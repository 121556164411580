import React from 'react';

const CreateFamilyTab = ({ familyName, setFamilyName, handleCreateFamily }) => (
  <div className="card mb-3">
    <div className="card-body">
      <h3 className="card-title">Create a Family</h3>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter family name"
          value={familyName}
          onChange={e => setFamilyName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleCreateFamily}>
          Create Family
        </button>
      </div>
    </div>
  </div>
);

export default CreateFamilyTab;
