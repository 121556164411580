import React, { useState } from 'react';

const BuildingReconUnits = ({ followers, onClose }) => {
  const [targetFamily, setTargetFamily] = useState('');
  const [soldiersAssigned, setSoldiersAssigned] = useState(3);
  
  const activeMissions = [
    { target: 'The Sopranos', timeRemaining: '3 hours', progress: 25 },
    { target: 'Corleone Family', timeRemaining: '1 hour', progress: 75 }
  ];
  
  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5>Recon Units</h5>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        <p>Gather intelligence on other families and territories.</p>
        
        <div className="card mb-4">
          <div className="card-body">
            <h6>New Recon Mission</h6>
            <div className="form-group">
              <label>Target Family:</label>
              <input 
                type="text" 
                className="form-control" 
                placeholder="Enter family name" 
                value={targetFamily}
                onChange={(e) => setTargetFamily(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Mission Type:</label>
              <select className="form-control">
                <option>General Intelligence</option>
                <option>Member Strength</option>
                <option>Territory Assessment</option>
                <option>Security Evaluation</option>
              </select>
            </div>
            <div className="form-group">
              <label>Assign Soldiers: {soldiersAssigned}</label>
              <input 
                type="range" 
                className="form-control-range" 
                min="1" 
                max={followers?.soldiers || 10} 
                value={soldiersAssigned}
                onChange={(e) => setSoldiersAssigned(parseInt(e.target.value))}
              />
              <small className="form-text text-muted">
                More soldiers = faster mission completion but higher risk of detection
              </small>
            </div>
            <button 
              className="btn btn-primary"
              disabled={!targetFamily.trim() || soldiersAssigned <= 0}
            >
              Send Recon Team
            </button>
          </div>
        </div>
        
        <h6>Active Recon Missions</h6>
        {activeMissions.length > 0 ? (
          <div className="list-group">
            {activeMissions.map((mission, index) => (
              <div key={index} className="list-group-item list-group-item-action">
                <div className="d-flex w-100 justify-content-between">
                  <h6 className="mb-1">Target: {mission.target}</h6>
                  <small>{mission.timeRemaining} remaining</small>
                </div>
                <div className="progress mt-2" style={{ height: '5px' }}>
                  <div 
                    className="progress-bar bg-info" 
                    role="progressbar" 
                    style={{ width: `${mission.progress}%` }} 
                    aria-valuenow={mission.progress} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="alert alert-info">
            No active recon missions at this time.
          </div>
        )}
        
        <div className="mt-4">
          <h6>Completed Missions</h6>
          <div className="list-group">
            <div className="list-group-item">
              <div className="d-flex w-100 justify-content-between">
                <h6 className="mb-1">Target: Gambino Family</h6>
                <small>Completed 2 days ago</small>
              </div>
              <p className="mb-1">Members: 25 | Territory: Downtown | Security: Medium</p>
              <button className="btn btn-sm btn-info">View Full Report</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingReconUnits;
