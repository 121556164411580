import React, { useState } from 'react';
import { useListUserItemsQuery, useSendItemMutation, usePawnItemMutation } from '../../api/game';
import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';

const ItemsPage = () => {
  const { data, isLoading, refetch } = useListUserItemsQuery();
  const [sendItem] = useSendItemMutation();
  const [pawnItem] = usePawnItemMutation();
  
  // State for modals
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'send' or 'pawn'
  const [selectedItem, setSelectedItem] = useState(null);
  const [receiverUsername, setReceiverUsername] = useState('');
  const [pawnQuantity, setPawnQuantity] = useState(1);
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  
  console.log('Raw data:', data); // Debug log
  
  // Convert numbered object keys to array
  const items = data ? Object.values(data).filter(item => item) : [];
  
  console.log('Processed items:', items); // Debug log

  // Handle opening the send modal
  const handleOpenSendModal = (item) => {
    setSelectedItem(item);
    setReceiverUsername('');
    setAlertData({ message: '', type: '' });
    setModalType('send');
    setShowModal(true);
  };

  // Handle opening the pawn modal
  const handleOpenPawnModal = (item) => {
    setSelectedItem(item);
    setPawnQuantity(1);
    setAlertData({ message: '', type: '' });
    setModalType('pawn');
    setShowModal(true);
  };

  // Handle sending an item
  const handleSendItem = async () => {
    if (!receiverUsername.trim()) {
      setAlertData({
        message: 'Please enter a receiver username',
        type: 'fail'
      });
      return;
    }

    try {
      const result = await sendItem({
        itemUuid: selectedItem.uuid,
        receiverUsername: receiverUsername.trim()
      }).unwrap();
      
      setAlertData({
        message: result.message || 'Item sent successfully!',
        type: 'success'
      });
      
      // Refetch the items list after successful send
      refetch();
      
      // Close the modal after a short delay
      setTimeout(() => {
        setShowModal(false);
      }, 1500);
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to send item. Please try again.',
        type: 'fail'
      });
    }
  };

  // Handle pawning an item
  const handlePawnItem = async () => {
    if (pawnQuantity < 1 || pawnQuantity > selectedItem.quantity) {
      setAlertData({
        message: `Please enter a quantity between 1 and ${selectedItem.quantity}`,
        type: 'fail'
      });
      return;
    }

    try {
      const result = await pawnItem({
        itemUuid: selectedItem.uuid,
        quantity: pawnQuantity
      }).unwrap();
      
      setAlertData({
        message: result.message || 'Item pawned successfully!',
        type: 'success'
      });
      
      // Refetch the items list after successful pawn
      refetch();
      
      // Close the modal after a short delay
      setTimeout(() => {
        setShowModal(false);
      }, 1500);
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to pawn item. Please try again.',
        type: 'fail'
      });
    }
  };

  // Get rarity color class
  const getRarityColorClass = (rarity) => {
    const rarityColors = {
      common: 'secondary',
      uncommon: 'success',
      rare: 'primary',
      epic: 'warning',
      legendary: 'danger'
    };
    return rarityColors[rarity] || 'secondary';
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">My Items</h3>
        {isLoading && <p>Loading...</p>}
        {items && items.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Rarity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.uuid}>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity} / {item.maxStack}</td>
                  <td>
                    <span className={`badge bg-${getRarityColorClass(item.rarity)}`}>
                      {item.rarity}
                    </span>
                  </td>
                  <td>
                    <div className="btn-group" role="group">
                      <button 
                        className="btn btn-sm btn-outline-primary" 
                        onClick={() => handleOpenSendModal(item)}
                      >
                        Send
                      </button>
                      <button 
                        className="btn btn-sm btn-outline-success" 
                        onClick={() => handleOpenPawnModal(item)}
                      >
                        Pawn
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No items are found</p>
        )}
      </div>

      {/* Modal for Send/Pawn */}
      {showModal && (
        <div
          className="modal"
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {modalType === 'send' ? 'Send Item' : 'Pawn Item'}
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Response message={alertData.message} type={alertData.type} />

                {selectedItem && (
                  <>
                    <p>
                      <strong>Item:</strong> {selectedItem.title}
                    </p>
                    <p>
                      <strong>Description:</strong> {selectedItem.description}
                    </p>
                    
                    {modalType === 'send' ? (
                      <div className="form-group">
                        <label htmlFor="receiverUsername">Receiver's Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="receiverUsername"
                          placeholder="Enter username"
                          value={receiverUsername}
                          onChange={(e) => setReceiverUsername(e.target.value)}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="form-group">
                          <label htmlFor="pawnQuantity">Quantity (Max: {selectedItem.quantity})</label>
                          <input
                            type="number"
                            className="form-control"
                            id="pawnQuantity"
                            min="1"
                            max={selectedItem.quantity}
                            value={pawnQuantity}
                            onChange={(e) => setPawnQuantity(parseInt(e.target.value) || 1)}
                          />
                        </div>
                        <p className="mt-3">
                          <strong>You will receive:</strong> £{formatNumber(50000 * pawnQuantity)}
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={modalType === 'send' ? handleSendItem : handlePawnItem}
                >
                  {modalType === 'send' ? 'Send Item' : 'Pawn Item'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemsPage;
