import React, { useState } from 'react';
import { useFamilyMassMessageMutation } from '../../../api/game';
import Response from '../../../shared-components/response';

const MassMessageTab = () => {
  const [message, setMessage] = useState('');
  const [sendMassMessage] = useFamilyMassMessageMutation();
  const [response, setResponse] = useState({ message: '', type: '' });

  const handleSendMessage = async () => {
    if (!message.trim()) {
      setResponse({ message: 'Message cannot be empty', type: 'error' });
      return;
    }
  
    try {
      const result = await sendMassMessage({ message }).unwrap();
      setResponse({ message: result.message, type: result.type });
      setMessage('');
    } catch (error) {
      setResponse({ message: 'Failed to send mass message', type: 'error' });
    }
  };
  
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Mass Message</h3>
        <Response message={response.message} type={response.type} />
        <div className="form-group">
          <textarea
            className="form-control"
            rows="4"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Type your message here..."
          ></textarea>
        </div>
        <button className="btn btn-primary" onClick={handleSendMessage}>
          Send Mass Message
        </button>
      </div>
    </div>
  );
};

export default MassMessageTab;
