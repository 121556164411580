import React, { useState } from 'react';
import { useFamilyRespondInviteMutation } from '../../../api/game';
import Response from '../../../shared-components/response';

const PendingInvitesTab = ({ invites }) => {
  const [respondToInvite] = useFamilyRespondInviteMutation();
  const [responseMessage, setResponseMessage] = useState({
    message: '',
    type: '',
  });

  const handleInviteResponse = async (inviteId, accept) => {
    try {
      const response = await respondToInvite({ inviteId, accept }).unwrap();
      setResponseMessage({ message: response.message, type: response.type });
    } catch (error) {
      setResponseMessage({
        message: 'Failed to respond to invite',
        type: 'error',
      });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Pending Invites</h3>
        <Response
          message={responseMessage.message}
          type={responseMessage.type}
        />
        {invites && invites.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Family Name</th>
                <th>Invited By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {invites.map(invite => (
                <tr key={invite.id}>
                  <td>{invite.familyName}</td>
                  <td>{invite.invitedBy}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-success me-2"
                      onClick={() => handleInviteResponse(invite.id, true)}
                    >
                      Accept
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleInviteResponse(invite.id, false)}
                    >
                      Decline
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No pending invites.</p>
        )}
      </div>
    </div>
  );
};

export default PendingInvitesTab;
