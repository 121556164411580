import React, { useState } from 'react';
import { useFamilyShowBarQuery, useFamilyBuyDrinkMutation } from '../../../api/game';
import Response from '../../../shared-components/response';

const FamilyBarTab = () => {
  const [selectedDrink, setSelectedDrink] = useState(null);
  const [username, setUsername] = useState('');
  const [response, setResponse] = useState({ message: '', type: '' });
  const { data: drinks, isLoading } = useFamilyShowBarQuery();
  const [sendDrink] = useFamilyBuyDrinkMutation();

  const handleSendDrink = async () => {
    if (selectedDrink && username) {
      try {
        const result = await sendDrink({
          drinkUuid: selectedDrink,
          username,
        }).unwrap();
        setResponse({ message: result.message, type: result.type });
      } catch (error) {
        setResponse({ message: 'Failed to send drink', type: 'error' });
      }
    }
  };

  if (isLoading) return <div>Loading drinks...</div>;

  // Convert the object to array if needed
  const drinksArray = drinks ? Object.values(drinks).filter(item => item.uuid) : [];

  return (
    <div className="card">
      <div className="card-body">
        <Response message={response.message} type={response.type} />
        {drinksArray.length > 0 ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Drink Name</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {drinksArray.map(drink => (
                  <tr key={drink.uuid}>
                    <td>{drink.drinkName}</td>
                    <td>
                      <input
                        type="radio"
                        name="drinkSelect"
                        value={drink.uuid}
                        checked={selectedDrink === drink.uuid}
                        onChange={() => setSelectedDrink(drink.uuid)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-3">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Enter username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={handleSendDrink}
                disabled={!selectedDrink || !username}
              >
                Send Drink
              </button>
            </div>
          </>
        ) : (
          <p>No drinks available in the family bar.</p>
        )}
      </div>
    </div>
  );
};

export default FamilyBarTab;
