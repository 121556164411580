import React, { useState } from 'react';
import {
  useBetatestingGiveXpMutation,
  useBetatestingGiveMoneyMutation,
  useBetatestingGivePointsMutation,
  useBetatestingGiveLockpicksMutation,
  useBetatestingGiveKevlarsMutation,
  useBetatestingGiveBulletsMutation,
  useBetatestingResetTimersMutation,
  useBetatestingEmptyJailMutation,
  useBetatestingGiveRandomEncounterItemMutation,
  useBetatestingGiveRandomItemMutation,

  useBetatestingFinishRefineryMutation,
  useBetatestingFindRandomVehicleMutation,
} from '../../api/game';
import Response from '../../shared-components/response';

const Beta = () => {
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [amount, setAmount] = useState('');

  const [giveXp] = useBetatestingGiveXpMutation();
  const [giveMoney] = useBetatestingGiveMoneyMutation();
  const [givePoints] = useBetatestingGivePointsMutation();
  const [giveLockpicks] = useBetatestingGiveLockpicksMutation();
  const [giveKevlars] = useBetatestingGiveKevlarsMutation();
  const [giveBullets] = useBetatestingGiveBulletsMutation();
  const [resetTimers] = useBetatestingResetTimersMutation();
  const [emptyJail] = useBetatestingEmptyJailMutation();
  const [giveRandomEncounterItem] = useBetatestingGiveRandomEncounterItemMutation();
  const [giveRandomItem] = useBetatestingGiveRandomItemMutation();


  const [finishRefinery] = useBetatestingFinishRefineryMutation();
  const [findRandomVehicle] = useBetatestingFindRandomVehicleMutation();

  const handleSubmit = async action => {
    try {
      let response;
      switch (action) {
        case 'xp':
          response = await giveXp({ amount: parseInt(amount) }).unwrap();
          break;
        case 'money':
          response = await giveMoney({ amount: parseInt(amount) }).unwrap();
          break;
        case 'points':
          response = await givePoints({ amount: parseInt(amount) }).unwrap();
          break;
        case 'lockpicks':
          response = await giveLockpicks({ amount: parseInt(amount) }).unwrap();
          break;
        case 'kevlars':
          response = await giveKevlars({ amount: parseInt(amount) }).unwrap();
          break;
        case 'bullets':
          response = await giveBullets({ amount: parseInt(amount) }).unwrap();
          break;
        case 'timers':
          response = await resetTimers().unwrap();
          break;
        case 'emptyJail':
          response = await emptyJail().unwrap();
          break;
        case 'finishRefinery':
            response = await finishRefinery().unwrap();
            break;
        case 'findRandomVehicle':
            response = await findRandomVehicle().unwrap();
            break;
        case 'encounterItem':
          response = await giveRandomEncounterItem().unwrap();
          break;
        case 'randomItem':
          response = await giveRandomItem().unwrap();
          break;
        default:
          break;
      }
      setAlertData({ message: response.message, type: response.type });
      setAmount('');
    } catch (error) {
      setAlertData({ message: error.message, type: 'fail' });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Beta Testing Tools</h3>
        <Response message={alertData.message} type={alertData.type} />

        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Give Resources</h4>
                <input
                  type="number"
                  className="form-control mb-2"
                  placeholder="Amount"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                />
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit('xp')}
                  >
                    Give XP(3k xp cap)
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit('money')}
                  >
                    Give Money
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit('points')}
                  >
                    Give Points
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit('lockpicks')}
                  >
                    Give Lockpicks
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit('kevlars')}
                  >
                    Give Kevlars
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleSubmit('bullets')}
                  >
                    Give Bullets
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Other Controls</h4>
                <div className="d-grid gap-2">
                <button
                    className="btn btn-warning"
                    onClick={() => handleSubmit('timers')}
                  >
                    Reset All Timers
                  </button>

                  <button
      className="btn btn-danger"
      onClick={() => handleSubmit('emptyJail')}
    >
      Empty Jail
    </button>
    <button
                className="btn btn-info"
                onClick={() => handleSubmit('finishRefinery')}
              >
                Finish Refinery
              </button>
              <button
                className="btn btn-success"
                onClick={() => handleSubmit('findRandomVehicle')}
              >
                Find Random Vehicle
              </button>
              <button
  className="btn btn-primary"
  onClick={() => handleSubmit('encounterItem')}
>
  Get Random Encounter Item
</button>
              <button
                className="btn btn-secondary"
                onClick={() => handleSubmit('randomItem')}
              >
                Get Random Item
              </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beta;
