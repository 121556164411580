import React from 'react';
import CreateFamilyTab from './CreateFamilyTab';
import PendingInvitesTab from './PendingInvitesTab';
import Response from '../../../shared-components/response';

const NoFamilySection = ({
  familyName,
  setFamilyName,
  handleCreateFamily,
  invites,
  alertData,
}) => (
  <div className="container">
    <Response message={alertData.message} type={alertData.type} />
    <CreateFamilyTab
      familyName={familyName}
      setFamilyName={setFamilyName}
      handleCreateFamily={handleCreateFamily}
    />
    <PendingInvitesTab invites={invites} />
  </div>
);

export default NoFamilySection;
