import React, { useEffect } from 'react';
import { AppSettings } from './config/app-settings.js';
import { useAppConfig } from './config/app-config.js';
import { GlobalStateProvider } from './auth/globalState.js';


import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import Timers from './components/timers/timers.jsx';
import ChatArea from './components/chatarea/chatArea.jsx';

function App() {
  const config = useAppConfig();

  useEffect(() => {
    let lastMouseX = 0;
    let lastMouseY = 0;
    let perfectMovements = 0;
    let lastActionTime = Date.now();
    let keyPressTimings = [];

    const detectMousePatterns = (e) => {
      const deltaX = Math.abs(e.clientX - lastMouseX);
      const deltaY = Math.abs(e.clientY - lastMouseY);
      
      if (deltaX === 0 || deltaY === 0) {
        perfectMovements++;
		if (perfectMovements > 10) {
			window._mp = (window._mp || 0) + 1;
		  }
      } else {
        perfectMovements = 0;
      }
      
      lastMouseX = e.clientX;
      lastMouseY = e.clientY;
    };

    const detectTimingPatterns = () => {
      const currentTime = Date.now();
      const timeDiff = currentTime - lastActionTime;
      
	  if (timeDiff > 0 && timeDiff < 50) {
		window._tp = (window._tp || 0) + 1;
	  }
	  
      
      lastActionTime = currentTime;
    };

    const detectKeyboardPatterns = (e) => {
      keyPressTimings.push(Date.now());
      
      if (keyPressTimings.length > 10) {
        const intervals = [];
        for (let i = 1; i < keyPressTimings.length; i++) {
          intervals.push(keyPressTimings[i] - keyPressTimings[i-1]);
        }
        
        const isConsistent = intervals.every(interval => 
          Math.abs(interval - intervals[0]) < 5
        );
        
		if (isConsistent) {
			window._kp = (window._kp || 0) + 1;
		  }
        
        keyPressTimings = [];
      }
    };

    const checkAutomationTools = () => {
      const isSelenium = !!window.document.$cdc_asdjflasutopfhvcZLmcfl_ 
        || !!window.document.$wdc_
        || !!window._Selenium_IDE_Recorder;
        
      const isTampermonkey = !!window.TM_getValue 
        || !!window.GM_getValue
        || !!window.GM;
        
		if (isSelenium || isTampermonkey) {
			window._at = 1;
		  }
		  
		  
    };

    // Set up event listeners
    window.addEventListener('mousemove', detectMousePatterns);
    window.addEventListener('click', detectTimingPatterns);
    window.addEventListener('keydown', detectKeyboardPatterns);
    
    // Initial automation tools check
    checkAutomationTools();

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', detectMousePatterns);
      window.removeEventListener('click', detectTimingPatterns);
      window.removeEventListener('keydown', detectKeyboardPatterns);
    };
  }, []);

  

  return (
	<GlobalStateProvider>
		<AppSettings.Provider value={config}>
		<div
			className={
			'app ' +
			(config.appGradientEnabled ? 'app-gradient-enabled ' : '') +
			(config.appHeaderNone ? 'app-without-header ' : '') +
			(config.appHeaderFixed && !config.appHeaderNone ? 'app-header-fixed ' : '') +
			(config.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
			(config.appSidebarNone ? 'app-without-sidebar ' : '') +
			(config.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
			(config.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
			(config.appSidebarMinify ? 'app-sidebar-minified ' : '') +
			(config.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') +
			(config.appTopMenu ? 'app-with-top-menu ' : '') +
			(config.appContentFullHeight ? 'app-content-full-height ' : '') +
			(config.appSidebarTwo ? 'app-with-two-sidebar ' : '') +
			(config.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
			(config.appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') +
			(config.hasScroll ? 'has-scroll ' : '')			}
		>
			{!config.appHeaderNone && <Header />}
			<div
            onClick={() => config.isChatOpen && config.toggleChatArea()}
          >
			{!config.appSidebarNone && <Sidebar />}
			{config.appSidebarTwo && <SidebarRight />}
			{config.appTopMenu && <TopMenu />}
            {!config.appContentNone && (
              <>
                <Timers />
                <Content />
              </>
            )}
		</div>
		<ChatArea isOpen={config.isChatOpen} />
        </div>
		</AppSettings.Provider>
	</GlobalStateProvider>
  );
}

export default App;