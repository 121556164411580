import React, { useState } from 'react';
import { useFamilyInviteMutation, useFamilyKickMutation } from '../../../api/game';
import Response from '../../../shared-components/response';

const ManagementTab = () => {
  const [inviteUsername, setInviteUsername] = useState('');
  const [kickUsername, setKickUsername] = useState('');
  const [inviteResponse, setInviteResponse] = useState({
    message: '',
    type: '',
  });
  const [kickResponse, setKickResponse] = useState({ message: '', type: '' });
  const [inviteUser] = useFamilyInviteMutation();
  const [kickUser] = useFamilyKickMutation();

  const handleInvite = async () => {
    try {
      const response = await inviteUser({ username: inviteUsername }).unwrap();
      setInviteResponse({ message: response.message, type: response.type });
      setInviteUsername('');
    } catch (error) {
      setInviteResponse({ message: 'Failed to invite user', type: 'error' });
    }
  };

  const handleKick = async () => {
    try {
      const response = await kickUser({ username: kickUsername }).unwrap();
      setKickResponse({ message: response.message, type: response.type });
      setKickUsername('');
    } catch (error) {
      setKickResponse({ message: 'Failed to kick user', type: 'error' });
    }
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3>Invite User to Family</h3>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter username"
                value={inviteUsername}
                onChange={e => setInviteUsername(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleInvite}>
                Invite
              </button>
            </div>
            <Response
              message={inviteResponse.message}
              type={inviteResponse.type}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h3>Kick User from Family</h3>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter username"
                value={kickUsername}
                onChange={e => setKickUsername(e.target.value)}
              />
              <button className="btn btn-danger" onClick={handleKick}>
                Kick
              </button>
            </div>
            <Response message={kickResponse.message} type={kickResponse.type} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementTab;
