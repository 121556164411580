import React, { useState } from 'react';
import Response from '../../../../shared-components/response';

const BuildingOC = ({ followers, onClose }) => {
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [response, setResponse] = useState({ message: '', type: '' });
  
  // Mock data - this would come from your API in the real implementation
  const cooldownRemaining = 0; // In seconds, 0 means no cooldown
  const formattedCooldown = formatCooldownTime(cooldownRemaining);
  const hasCooldown = cooldownRemaining > 0;
  
  const operations = [
    {
      id: 'low',
      name: 'Reconnaissance',
      description: 'Gather intelligence with minimal exposure. Workers will be consumed in the operation.',
      workers: 100,
      risk: 'Low',
      reward: 'Low',
      color: 'success',
      successRate: '70%',
      potentialReward: '£500,000 - £1,500,000'
    },
    {
      id: 'medium',
      name: 'Tactical Strike',
      description: 'Execute a planned operation against a target. All workers will be consumed in the operation.',
      workers: 250,
      risk: 'Medium',
      reward: 'Medium',
      color: 'warning',
      successRate: '55%',
      potentialReward: '£1,500,000 - £3,500,000'
    },
    {
      id: 'high',
      name: 'Full Assault',
      description: 'Commit significant resources for maximum impact. All workers will be consumed in the operation.',
      workers: 500,
      risk: 'High',
      reward: 'High',
      color: 'danger',
      successRate: '40%',
      potentialReward: '£3,500,000 - £7,000,000'
    }
  ];
  
  function formatCooldownTime(seconds) {
    if (seconds <= 0) return 'Ready';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    return `${hours}h ${minutes}m`;
  }
  
  const handleOperationSelect = (operation) => {
    setSelectedOperation(operation);
  };
  
  const handleLaunchOperation = () => {
    if (!selectedOperation) return;
    
    // This would be replaced with your actual API call
    if ((followers?.workers || 0) < selectedOperation.workers) {
      setResponse({
        message: `Not enough workers available. Need ${selectedOperation.workers} workers.`,
        type: 'error'
      });
      return;
    }
    
    // Mock success response
    setResponse({
      message: `${selectedOperation.name} operation launched successfully! ${selectedOperation.workers} workers have been deployed.`,
      type: 'success'
    });
    
    // Reset selection
    setSelectedOperation(null);
  };
  
  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center bg-dark text-white">
        <h5 className="mb-0">
          <i className="fas fa-shield-alt mr-2"></i>
          Operations Center
        </h5>
        <button className="btn btn-sm btn-outline-light" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        <Response message={response.message} type={response.type} />
        
        <div className="alert alert-info d-flex justify-content-between align-items-center">
          <div>
            <strong>Available Workers:</strong> {followers?.workers || 0}
          </div>
          <div>
            <strong>Cooldown Status:</strong> {formattedCooldown}
          </div>
        </div>
        
        <div className="alert alert-warning">
          <i className="fas fa-exclamation-triangle mr-2"></i>
          <strong>Important:</strong> Workers assigned to operations are permanently consumed regardless of success or failure.
        </div>
        
        <h6 className="text-center mb-3">Select an Operation Type</h6>
        
        <div className="row mb-4">
          {operations.map(op => (
            <div className="col-md-4 mb-3" key={op.id}>
              <div 
                className={`card h-100 ${selectedOperation?.id === op.id ? 'border-primary' : ''}`}
                onClick={() => handleOperationSelect(op)}
                style={{ cursor: 'pointer' }}
              >
                <div className={`card-header bg-${op.color} text-white`}>
                  <h6 className="mb-0">{op.name}</h6>
                </div>
                <div className="card-body">
                  <p className="card-text small">{op.description}</p>
                  <div className="d-flex justify-content-between">
                    <span><i className="fas fa-users"></i> {op.workers} workers</span>
                    <span><i className="fas fa-exclamation-triangle"></i> {op.risk} risk</span>
                  </div>
                  <div className="mt-2">
                    <div><i className="fas fa-percentage mr-1"></i> Success rate: {op.successRate}</div>
                    <div><i className="fas fa-gem mr-1"></i> Potential reward: {op.potentialReward}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        <div className="d-flex flex-column align-items-center">
          <button 
            className="btn btn-primary btn-lg"
            disabled={!selectedOperation || hasCooldown || (followers?.workers || 0) < (selectedOperation?.workers || 0)}
            onClick={handleLaunchOperation}
          >
            {hasCooldown ? (
              <>
                <i className="fas fa-hourglass-half mr-2"></i>
                On Cooldown ({formattedCooldown})
              </>
            ) : (
              <>
                <i className="fas fa-rocket mr-2"></i>
                Launch Operation
              </>
            )}
          </button>
          
          {selectedOperation && (
            <div className="mt-3 text-center">
              <h6>Operation Details</h6>
              <p className="mb-1">
                <strong>Type:</strong> {selectedOperation.name}
              </p>
              <p className="mb-1">
                <strong>Workers Required:</strong> {selectedOperation.workers} 
                <span className="text-danger ml-1">(will be consumed)</span>
              </p>
              <p className="mb-1">
                <strong>Success Rate:</strong> {selectedOperation.successRate}
              </p>
              <p className="mb-1">
                <strong>Risk Level:</strong> {selectedOperation.risk}
              </p>
              <p className="mb-0">
                <strong>Potential Reward:</strong> {selectedOperation.potentialReward} <span className="text-muted">(per member)</span>
              </p>
            </div>
          )}
          
          {!selectedOperation && (
            <div className="mt-3 text-center text-muted">
              <p>Select an operation type to continue</p>
            </div>
          )}
          
          {selectedOperation && (followers?.workers || 0) < selectedOperation.workers && (
            <div className="alert alert-danger mt-3">
              <i className="fas fa-exclamation-circle mr-2"></i>
              Not enough workers available. Need {selectedOperation.workers} workers.
            </div>
          )}
        </div>
        
        <div className="mt-4 p-3 border rounded bg-light">
          <h6><i className="fas fa-info-circle mr-2"></i>Operation Information</h6>
          <ul className="mb-0 pl-3">
            <li>Operations can be launched once every 12 hours.</li>
            <li>All workers assigned to an operation are permanently consumed.</li>
            <li>Higher risk operations have lower success rates but higher potential rewards.</li>
            <li>Failed operations still consume workers but provide no rewards.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BuildingOC;
