import React, { useState } from 'react';

const BuildingItemFactory = ({ followers, onClose }) => {
  const [selectedItem, setSelectedItem] = useState('Med Kits');
  
  const items = [
    { name: 'Med Kits', icon: 'fa-first-aid', workersRequired: 5, productionRate: '5/hour' },
    { name: 'Lockpicks', icon: 'fa-key', workersRequired: 3, productionRate: '10/hour' },
    { name: 'Explosives', icon: 'fa-bomb', workersRequired: 10, productionRate: '2/hour' },
    { name: 'Handcuffs', icon: 'fa-handcuffs', workersRequired: 2, productionRate: '8/hour' },
    { name: 'Disguises', icon: 'fa-mask', workersRequired: 7, productionRate: '3/hour' },
    { name: 'Smoke Bombs', icon: 'fa-cloud', workersRequired: 4, productionRate: '6/hour' }
  ];
  
  const selectedItemData = items.find(item => item.name === selectedItem);
  
  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5>Item Factory</h5>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        <p>Produce various items for your family operations.</p>
        
        <div className="row mb-4">
          {items.map((item, index) => (
            <div key={index} className="col-md-4 mb-3">
              <div 
                className={`card ${selectedItem === item.name ? 'border-primary' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedItem(item.name)}
              >
                <div className="card-body text-center">
                  <i className={`fas ${item.icon} fa-2x mb-2`}></i>
                  <h5>{item.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {selectedItemData && (
          <div className="card bg-light">
            <div className="card-body">
              <h6>Production Details: {selectedItemData.name}</h6>
              <div className="row">
                <div className="col-md-6">
                  <p><strong>Workers Required:</strong> {selectedItemData.workersRequired}</p>
                  <p><strong>Production Rate:</strong> {selectedItemData.productionRate}</p>
                </div>
                <div className="col-md-6">
                  <p><strong>Available Workers:</strong> {followers?.workers || 0}</p>
                  <p><strong>In Storage:</strong> 15</p>
                </div>
              </div>
              <button 
                className="btn btn-success"
                disabled={followers?.workers < selectedItemData.workersRequired}
              >
                Start Production
              </button>
              {followers?.workers < selectedItemData.workersRequired && (
                <div className="text-danger mt-2">
                  Not enough workers available!
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildingItemFactory;
