import React, { useState } from 'react';
import {
  useFamilyPageQuery,
  useFamilyCreateFamilyMutation,
  useFamilyListInvitesQuery,
  useFamilyListBusinessesQuery,
  useFamilyShowFollowersQuery,
  useFamilyShowBarQuery,
} from '../../api/game';

import FamilyTabs from './components/FamilyTabs';
import NoFamilySection from './components/NoFamilySection';

const Family = () => {
  const [familyName, setFamilyName] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [activeTab, setActiveTab] = useState('home');

  const {
    data: familyInfo,
    isLoading,
    refetch: refetchFamilyInfo,
  } = useFamilyPageQuery();
  const [createFamily] = useFamilyCreateFamilyMutation();
  const { data: invites, refetch: refetchInvites } = useFamilyListInvitesQuery();
  const { refetch: refetchBusinesses } = useFamilyListBusinessesQuery();
  const { refetch: refetchFollowers } = useFamilyShowFollowersQuery();
  const { refetch: refetchFamilyDrinks } = useFamilyShowBarQuery();

  const handleCreateFamily = async () => {
    try {
      const response = await createFamily({ name: familyName }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      setFamilyName('');
    } catch (error) {
      setAlertData({ message: 'Failed to create family', type: 'error' });
    }
  };
  
  const handleTabClick = tabName => {
    setActiveTab(tabName);
    refetchFamilyInfo();

    // Refetch data based on the selected tab
    switch (tabName) {
      case 'home':
        refetchFamilyInfo();
        break;
      case 'business':
        refetchBusinesses();
        break;
      case 'followers':
        refetchFollowers();
        break;
      case 'family-bar':
        refetchFamilyDrinks();
        break;
      // Add more cases for other tabs as needed
    }
  };

  if (isLoading) return <div>Loading...</div>;

  if (familyInfo.family === 'none') {
    return (
      <NoFamilySection
        familyName={familyName}
        setFamilyName={setFamilyName}
        handleCreateFamily={handleCreateFamily}
        invites={invites}
        alertData={alertData}
      />
    );
  }

  return (
    <FamilyTabs
      familyInfo={familyInfo}
      activeTab={activeTab}
      handleTabClick={handleTabClick}
    />
  );
};

export default Family;
