import React from 'react';

const BuildingArmory = ({ followers, onClose }) => {
  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5>Armory</h5>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        <p>Store and manage your family's weapons and equipment.</p>
        
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="fas fa-bullseye fa-2x mb-2"></i>
                <h5>Bullets</h5>
                <p className="mb-0">10,000</p>
                <div className="mt-2">
                  <button className="btn btn-sm btn-outline-primary">Distribute</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="fas fa-shield-alt fa-2x mb-2"></i>
                <h5>Kevlar</h5>
                <p className="mb-0">50</p>
                <div className="mt-2">
                  <button className="btn btn-sm btn-outline-primary">Distribute</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <div className="card-body text-center">
                <i className="fas fa-first-aid fa-2x mb-2"></i>
                <h5>Med Kits</h5>
                <p className="mb-0">25</p>
                <div className="mt-2">
                  <button className="btn btn-sm btn-outline-primary">Distribute</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="mt-3">
          <h6>Weapons Storage</h6>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Weapon</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pistols</td>
                  <td>15</td>
                  <td>
                    <button className="btn btn-sm btn-outline-primary">Distribute</button>
                  </td>
                </tr>
                <tr>
                  <td>Shotguns</td>
                  <td>8</td>
                  <td>
                    <button className="btn btn-sm btn-outline-primary">Distribute</button>
                  </td>
                </tr>
                <tr>
                  <td>Assault Rifles</td>
                  <td>5</td>
                  <td>
                    <button className="btn btn-sm btn-outline-primary">Distribute</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingArmory;
