import React, { useState } from 'react';
import { 
  useFamilyKevlarFactoryInfoQuery, 
  useFamilyassignFactoryWorkersMutation,
  useFamilywithdrawFromFactoryMutation
} from '../../../../api/game';
import Response from '../../../../shared-components/response';

const BuildingKevlarFactory = ({ followers, onClose }) => {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [workersToAssign, setWorkersToAssign] = useState(0);
  const [response, setResponse] = useState({ message: '', type: '' });
  
  const { 
    data: factoryInfo, 
    isLoading, 
    error, 
    refetch 
  } = useFamilyKevlarFactoryInfoQuery();
  
  const [assignWorkers, { isLoading: isAssigning }] = useFamilyassignFactoryWorkersMutation();
  const [withdrawItems, { isLoading: isWithdrawing }] = useFamilywithdrawFromFactoryMutation();
  
  const handleWithdraw = async () => {
    if (!withdrawAmount || withdrawAmount <= 0) return;
    
    try {
      const result = await withdrawItems({ 
        factoryType: 'kevlar',
        amount: withdrawAmount
      }).unwrap();
      
      setResponse({
        message: result.message,
        type: result.type
      });
      
      if (result.type === 'success') {
        setWithdrawAmount(0);
        refetch();
      }
    } catch (err) {
      setResponse({
        message: 'Failed to withdraw kevlar',
        type: 'error'
      });
    }
  };
  
  const handleAssignWorkers = async () => {
    if (!workersToAssign || workersToAssign <= 0) return;
    
    try {
      const result = await assignWorkers({ 
        factoryType: 'kevlar',
        workersToAssign: workersToAssign
      }).unwrap();
      
      setResponse({
        message: result.message,
        type: result.type
      });
      
      if (result.type === 'success') {
        setWorkersToAssign(0);
        refetch();
      }
    } catch (err) {
      setResponse({
        message: 'Failed to assign workers',
        type: 'error'
      });
    }
  };
  
  if (isLoading) {
    return (
      <div className="card mt-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Kevlar Factory</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card mt-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Kevlar Factory</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body">
          <div className="alert alert-danger">Error loading kevlar factory data</div>
        </div>
      </div>
    );
  }

  // Check if we have a message indicating factory is not unlocked
  if (factoryInfo?.message && factoryInfo.type === 'error') {
    return (
      <div className="card mt-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Kevlar Factory</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body">
          <div className="alert alert-info">{factoryInfo.message}</div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5>Kevlar Factory</h5>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        <Response message={response.message} type={response.type} />
        
        <div className="alert alert-info">
          Available Family Workers: {followers?.workers || 0}
        </div>
        
        {/* Kevlar Stock and Withdrawal Section */}
        <div className="p-3 border rounded bg-light">
          <h6>Kevlar Inventory</h6>
          <div className="d-flex align-items-center mb-2">
            <div className="mr-3">
              <strong>Current Kevlar Stock:</strong> {factoryInfo?.kevlarStock.toLocaleString() || 0}
            </div>
            <div className="input-group" style={{ maxWidth: '200px' }}>
              <input 
                type="number" 
                className="form-control form-control-sm" 
                placeholder="Amount" 
                min="0" 
                max={factoryInfo?.kevlarStock || 0}
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(Math.min(parseInt(e.target.value) || 0, factoryInfo?.kevlarStock || 0))}
                disabled={isWithdrawing}
              />
              <div className="input-group-append">
                <button 
                  className="btn btn-sm btn-outline-primary" 
                  onClick={handleWithdraw}
                  disabled={withdrawAmount <= 0 || withdrawAmount > (factoryInfo?.kevlarStock || 0) || isWithdrawing}
                >
                  {isWithdrawing ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : 'Withdraw'}
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Worker Assignment Section */}
        <div className="mt-4 p-3 border rounded bg-light">
          <h6>Worker Management</h6>
          <p className="text-muted small">This factory consumes {factoryInfo?.dailyWorkerConsumption || 150} workers per day to produce kevlar.</p>
          
          <div className="d-flex align-items-center mb-2">
            <div className="mr-3">
              <strong>Assigned Workers:</strong> {factoryInfo?.assignedWorkers || 0}/{factoryInfo?.maxWorkers || 600}
            </div>
            <div className="input-group" style={{ maxWidth: '200px' }}>
              <input 
                type="number" 
                className="form-control form-control-sm" 
                placeholder="Workers" 
                min="0" 
                max={(followers?.workers || 0)}
                value={workersToAssign}
                onChange={(e) => setWorkersToAssign(Math.min(parseInt(e.target.value) || 0, followers?.workers || 0))}
                disabled={isAssigning}
              />
              <div className="input-group-append">
                <button 
                  className="btn btn-sm btn-outline-success" 
                  onClick={handleAssignWorkers}
                  disabled={workersToAssign <= 0 || workersToAssign > (followers?.workers || 0) || isAssigning}
                >
                  {isAssigning ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : 'Assign'}
                </button>
              </div>
            </div>
          </div>
          
          <div className="progress" style={{ height: '10px' }}>
            <div 
              className="progress-bar bg-success" 
              role="progressbar" 
              style={{ width: `${((factoryInfo?.assignedWorkers || 0) / (factoryInfo?.maxWorkers || 600)) * 100}%` }} 
              aria-valuenow={factoryInfo?.assignedWorkers || 0} 
              aria-valuemin="0" 
              aria-valuemax={factoryInfo?.maxWorkers || 600}
            ></div>
          </div>
          <div className="d-flex justify-content-between">
            <small>0</small>
            <small>{factoryInfo?.maxWorkers || 600}</small>
          </div>
        </div>
        
        <div className="mt-4">
          <h6>Production Statistics</h6>
          <div className="table-responsive">
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td>Total Produced:</td>
                  <td>{(factoryInfo?.totalProduced || 0).toLocaleString()} units</td>
                </tr>
                <tr>
                  <td>Total Withdrawn:</td>
                  <td>{(factoryInfo?.totalWithdrawn || 0).toLocaleString()} units</td>
                </tr>
                <tr>
                  <td>Last Withdrawal:</td>
                  <td>{factoryInfo?.lastWithdrawTime ? new Date(factoryInfo.lastWithdrawTime).toLocaleString() : 'Never'}</td>
                </tr>
                <tr>
                  <td>Workers Assigned:</td>
                  <td>{factoryInfo?.assignedWorkers || 0}</td>
                </tr>
                <tr>
                  <td>Daily Worker Consumption:</td>
                  <td>{factoryInfo?.dailyWorkerConsumption || 150} workers</td>
                </tr>
                <tr>
                  <td>Daily Production (at full capacity):</td>
                  <td>2,500 kevlar</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingKevlarFactory;
