import React from 'react';
import HomeTab from './HomeTab';
import BusinessTab from './BusinessTab';
import FollowersTab from './FollowersTab';
import FamilyBarTab from './FamilyBarTab';
import MassMessageTab from './MassMessageTab';
import ProfileTab from './ProfileTab';
import ManagementTab from './ManagementTab';

const FamilyTabs = ({ familyInfo, activeTab, handleTabClick }) => (
  <div className="family-tabs-container">
    {/* Tab navigation */}
    <div className="tabs-wrapper">
      <ul className="nav nav-tabs flex-nowrap">
        {familyInfo.visibleTabs.map(
          tab =>
            tab.visible && (
              <li className="nav-item" key={tab.id}>
                <a
                  href={`#${tab.name.toLowerCase().replace(' ', '-')}-tab`}
                  onClick={() =>
                    handleTabClick(tab.name.toLowerCase().replace(' ', '-'))
                  }
                  className={`nav-link ${activeTab === tab.name.toLowerCase().replace(' ', '-') ? 'active' : ''}`}
                >
                  {tab.name}
                </a>
              </li>
            )
        )}
      </ul>
    </div>
    
    {/* Tab content */}
    <div className="tab-content panel rounded-0 p-3 m-0">
      <div
        className={`tab-pane fade ${activeTab === 'home' ? 'active show' : ''}`}
        id="home-tab"
      >
        <HomeTab familyInfo={familyInfo} />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'business' ? 'active show' : ''}`}
        id="business-tab"
      >
        <BusinessTab familyInfo={familyInfo} />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'followers' ? 'active show' : ''}`}
        id="followers-tab"
      >
        <FollowersTab familyInfo={familyInfo} />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'family-bar' ? 'active show' : ''}`}
        id="family-bar-tab"
      >
        <FamilyBarTab />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'mass-message' ? 'active show' : ''}`}
        id="mass-message-tab"
      >
        <MassMessageTab />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'profile' ? 'active show' : ''}`}
        id="profile-tab"
      >
        <ProfileTab />
      </div>
      <div
        className={`tab-pane fade ${activeTab === 'management' ? 'active show' : ''}`}
        id="management-tab"
      >
        <ManagementTab />
      </div>
    </div>
  </div>
);

export default FamilyTabs;
