import React, { useState } from 'react';
import { useFamilyMembersHealthQuery, useHealFamilyMemberMutation } from '../../../../api/game';
import Response from '../../../../shared-components/response';

const BuildingHospital = ({ followers, onClose }) => {
  const [selectedMember, setSelectedMember] = useState('');
  const { data: healthData, isLoading, error, refetch } = useFamilyMembersHealthQuery();
  const [healMember, { isLoading: isHealing }] = useHealFamilyMemberMutation();
  const [response, setResponse] = useState({ message: '', type: '' });

  const handleSelectChange = (e) => {
    setSelectedMember(e.target.value);
  };

  const handleHealRegular = async () => {
    if (!selectedMember || followers?.workers < 50) return;
    
    try {
      const result = await healMember({ 
        memberUuid: selectedMember, 
        healType: 'standard' 
      }).unwrap();
      
      setResponse({
        message: result.message,
        type: result.type
      });
      
      if (result.type === 'success') {
        refetch(); // Refresh the members list
      }
    } catch (err) {
      setResponse({
        message: 'Failed to heal family member',
        type: 'error'
      });
    }
  };

  const handleHealPremium = async () => {
    if (!selectedMember || followers?.workers < 100) return;
    
    try {
      const result = await healMember({ 
        memberUuid: selectedMember, 
        healType: 'premium' 
      }).unwrap();
      
      setResponse({
        message: result.message,
        type: result.type
      });
      
      if (result.type === 'success') {
        refetch(); // Refresh the members list
      }
    } catch (err) {
      setResponse({
        message: 'Failed to heal family member',
        type: 'error'
      });
    }
  };

  if (isLoading) {
    return (
      <div className="card mt-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Hospital</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card mt-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Hospital</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body">
          <div className="alert alert-danger">Error loading hospital data</div>
        </div>
      </div>
    );
  }

  // Check if we have a message indicating hospital is not unlocked
  if (healthData?.message && healthData.type === 'info') {
    return (
      <div className="card mt-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Hospital</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body">
          <div className="alert alert-info">{healthData.message}</div>
        </div>
      </div>
    );
  }

  const aliveMembers = healthData?.members || [];

  return (
    <div className="card mt-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5>Hospital</h5>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        <p>Heal your family members to restore their health.</p>
        
        <Response message={response.message} type={response.type} />
        
        <div className="alert alert-info">
          Available Workers: {followers?.workers || 0}
        </div>
        
        <div className="form-group mb-4">
          <label htmlFor="familyMemberSelect">Select Family Member to Heal:</label>
          <select 
            className="form-control" 
            id="familyMemberSelect"
            value={selectedMember}
            onChange={handleSelectChange}
            disabled={isHealing}
          >
            <option value="">-- Select a family member --</option>
            {aliveMembers.map((member) => (
              <option key={member.id} value={member.id}>
                {member.name} - Current Health: {member.health}/129
              </option>
            ))}
          </select>
        </div>
        
        <div className="d-flex justify-content-center gap-4 mt-4">
          <button 
            className="btn btn-primary" 
            onClick={handleHealRegular}
            disabled={!selectedMember || (followers?.workers || 0) < 50 || isHealing}
          >
            {isHealing ? (
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            ) : null}
            Standard Healing (50 workers)
            <div className="small">Heal to 100 health</div>
          </button>
          
          <button 
            className="btn btn-success" 
            onClick={handleHealPremium}
            disabled={!selectedMember || (followers?.workers || 0) < 100 || isHealing}
          >
            {isHealing ? (
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            ) : null}
            Premium Healing (100 workers)
            <div className="small">Heal to 129 health</div>
          </button>
        </div>
        
        {!selectedMember && aliveMembers.length > 0 && (
          <div className="alert alert-warning mt-3">
            Please select a family member to heal
          </div>
        )}
        
        {selectedMember && (followers?.workers || 0) < 50 && (
          <div className="alert alert-danger mt-3">
            Not enough workers available for healing
          </div>
        )}
        
        {aliveMembers.length === 0 && (
          <div className="alert alert-warning mt-3">
            No family members available to heal
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildingHospital;
