import React, { useState } from 'react';
import { useFamilyPageQuery, useFamilyLeaveMutation } from '../../../api/game';
import Response from '../../../shared-components/response';
import { formatNumber } from '../../../shared-components/numberFormat';

const HomeTab = ({ familyInfo }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [leaveFamily] = useFamilyLeaveMutation();
  const [response, setResponse] = useState({ message: '', type: '' });
  const { refetch } = useFamilyPageQuery();

  if (!familyInfo) {
    return <div>Loading...</div>;
  }

  const handleLeaveFamily = async () => {
    try {
      const result = await leaveFamily().unwrap();
      setResponse({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setShowConfirmModal(false);
        refetch();
      }
    } catch (error) {
      setResponse({ message: 'Failed to leave family', type: 'error' });
    }
  };

  return (
    <div className="family-home">
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title text-center">{familyInfo.familyName}</h2>
          <hr className="my-4" />
          <p className="card-text">{familyInfo.familyMessage}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Leadership</h4>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Boss: {familyInfo.leaderName}
                </li>
                <li className="list-group-item">RHM: {familyInfo.rhmName}</li>
                <li className="list-group-item">LHM: {familyInfo.lhmName}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Information</h4>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Total Members: {familyInfo.totalMembers}
                </li>
                <li className="list-group-item">
                  Daily Payout: £{formatNumber(familyInfo.dailyPayout)} per
                  member
                </li>
                <li className="list-group-item">
                  Total Businesses: {familyInfo.businessCount}
                </li>
                <li className="list-group-item">Tax Rate: {familyInfo.tax}%</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body text-center">
          <p>It will cost £{formatNumber(1000000)} to leave your family.</p>

          <hr className="my-3" />
          <button
            className="btn btn-danger"
            onClick={() => setShowConfirmModal(true)}
          >
            Leave Family
          </button>
        </div>
      </div>

      {showConfirmModal && (
        <div
          className="modal"
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Leave Family</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowConfirmModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to leave the family? This will cost
                  £1,000,000.
                </p>
                {response.message && (
                  <Response message={response.message} type={response.type} />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleLeaveFamily}
                >
                  Leave Family
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeTab;
