import React from 'react';
import { formatNumber } from '../../../../shared-components/numberFormat';

const BuildingButton = ({ building, isUnderConstruction, cost, icon, onClick }) => {
  return (
    <button
      className={`btn btn-block ${
        building.unlocked 
          ? 'btn-success' 
          : isUnderConstruction 
            ? 'btn-warning' 
            : 'btn-danger'
      }`}
      onClick={onClick}
      disabled={!building.unlocked && isUnderConstruction}
      style={{
        width: '100%',
        height: '60px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span>
        <i className={`fas ${icon} mr-2`}></i>
        {building.name}
      </span>
      {!building.unlocked && !isUnderConstruction && (
        <small style={{ fontSize: '0.8em' }}>
          Build for {formatNumber(cost)} workers
        </small>
      )}
      {isUnderConstruction && (
        <small style={{ fontSize: '0.8em' }}>
          Under Construction
        </small>
      )}
    </button>
  );
};

export default BuildingButton;
