import React, { useState } from 'react';
import { useFamilyShowFollowersQuery, useFamilyRecruitFollowersMutation, useFamilyBuildBuildingMutation } from '../../../api/game';
import Response from '../../../shared-components/response';
import { formatNumber } from '../../../shared-components/numberFormat';
import BuildingButton from './building-areas/BuildingButton';
import BuildingStats from './building-areas/BuildingStats';
import BuildingOC from './building-areas/BuildingOC';
import BuildingHospital from './building-areas/BuildingHospital';
import BuildingBulletFactory from './building-areas/BuildingBulletFactory';
import BuildingKevlarFactory from './building-areas/BuildingKevlarFactory';
import BuildingArmory from './building-areas/BuildingArmory';
import BuildingItemFactory from './building-areas/BuildingItemFactory';
import BuildingReconUnits from './building-areas/BuildingReconUnits';

const FollowersTab = ({ familyInfo }) => {
  const { data: followers } = useFamilyShowFollowersQuery();
  const [recruitFollower] = useFamilyRecruitFollowersMutation();
  const [buildBuilding] = useFamilyBuildBuildingMutation();

  const [response, setResponse] = useState({ message: '', type: '' });
  const [activeBuilding, setActiveBuilding] = useState(null);

  const followerTypes = ['workers', 'soldiers', 'guards'];

  const getBuildingIcon = buildingName => {
    const icons = {
      Stats: 'fa-chart-bar',
      OC: 'fa-users',
      Hospital: 'fa-hospital',
      'Bullet Factory': 'fa-bullseye',
      'Kevlar Factory': 'fa-shield-alt',
      Armory: 'fa-warehouse',
      'Item Factory': 'fa-box',
      'Recon Units': 'fa-binoculars',
    };
    return icons[buildingName] || 'fa-building';
  };

  const handleBuildingClick = async buildingName => {
    try {
      const result = await buildBuilding({ buildingName }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({
        message: 'Failed to start building construction',
        type: 'error',
      });
    }
  };

  const handleBuildingSelect = (building) => {
    // If building is unlocked, set it as active
    if (building.unlocked) {
      setActiveBuilding(building.name === activeBuilding ? null : building.name);
    } else {
      // If not unlocked, try to build it
      handleBuildingClick(building.name);
    }
  };

  const handleRecruitFollower = async type => {
    try {
      const result = await recruitFollower({ type }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({
        message: error.data?.message || 'Failed to recruit follower',
        type: 'error',
      });
    }
  };

  // Render the active building area
  const renderActiveBuildingArea = () => {
    if (!activeBuilding) return null;

    switch (activeBuilding) {
      case 'Stats':
        return <BuildingStats followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'OC':
        return <BuildingOC followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'Hospital':
        return <BuildingHospital followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'Bullet Factory':
        return <BuildingBulletFactory followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'Kevlar Factory':
        return <BuildingKevlarFactory followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'Armory':
        return <BuildingArmory followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'Item Factory':
        return <BuildingItemFactory followers={followers} onClose={() => setActiveBuilding(null)} />;
      case 'Recon Units':
        return <BuildingReconUnits followers={followers} onClose={() => setActiveBuilding(null)} />;
      default:
        return null;
    }
  };

  // If there's an active building, render only that component
  if (activeBuilding) {
    return (
      <div className="row">
        <div className="col-12">
          {renderActiveBuildingArea()}
        </div>
      </div>
    );
  }

  // Otherwise render the default view with followers and buildings
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <Response message={response.message} type={response.type} />
            <div className="d-flex justify-content-between">
              {followerTypes.map(type => (
                <div
                  key={type}
                  className="position-relative"
                  style={{ width: '30%' }}
                >
                  <img
                    src={`https://placehold.co/600x400`}
                    alt={type}
                    style={{ width: '100%', cursor: 'pointer' }}
                    onDoubleClick={() => handleRecruitFollower(type)}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'rgba(0,0,0,0.7)',
                      color: 'white',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}:{' '}
                    {followers?.[type] || 0}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {(familyInfo.userRole === 'leader' ||
        familyInfo.userRole === 'rhm' ||
        familyInfo.userRole === 'lhm') && (
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {followers?.buildings?.map((building, index) => {
                  const cost = [
                    'Stats',
                    'OC',
                    'Hospital',
                    'Item Factory',
                    'Recon Units',
                  ].includes(building.name)
                    ? 1000
                    : 2500;
                  const isUnderConstruction =
                    followers.currentlyBuilding === building.name;
                  
                  return (
                    <div key={index} className="col-6 mb-3">
                      <BuildingButton
                        building={building}
                        isUnderConstruction={isUnderConstruction}
                        cost={cost}
                        icon={getBuildingIcon(building.name)}
                        onClick={() => handleBuildingSelect(building)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FollowersTab;
