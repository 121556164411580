import React from 'react';
import { useFamilyBuildingStatsQuery } from '../../../../api/game';
import { formatNumber } from '../../../../shared-components/numberFormat';

const BuildingStats = ({ onClose }) => {
  const { data: familyStats, isLoading, error } = useFamilyBuildingStatsQuery();

  if (isLoading) {
    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Family Stats</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Family Stats</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body">
          <div className="alert alert-danger">Error loading family stats</div>
        </div>
      </div>
    );
  }

  // Check if we have a message indicating stats are not unlocked
  if (familyStats?.message && familyStats.type === 'info') {
    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Family Stats</h5>
          <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
        </div>
        <div className="card-body">
          <div className="alert alert-info">{familyStats.message}</div>
        </div>
      </div>
    );
  }

  // Check if we have members data
  const hasMembers = familyStats?.members && familyStats.members.length > 0;

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5>Family Stats</h5>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>Close</button>
      </div>
      <div className="card-body">
        {!hasMembers ? (
          <div className="alert alert-warning">No family members found</div>
        ) : (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Username</th>
                  <th>Rank</th>
                  <th>Money</th>
                  <th>Weapon</th>
                  <th>Protection</th>
                  <th>Workers Recruited</th>
                  <th>Soldiers Recruited</th>
                  <th>Guards Recruited</th>
                  <th>Last Active</th>
                </tr>
              </thead>
              <tbody>
                {familyStats.members.map((member, index) => (
                  <tr key={index}>
                    <td>
                      {member.username} {' '}
                      {member.status === 'Alive' ? (
                        <span className="badge bg-success text-white">Alive</span>
                      ) : member.status === 'Dead' ? (
                        <span className="badge bg-danger text-white">Dead</span>
                      ) : member.status === 'Banned' ? (
                        <span className="badge bg-warning text-dark">Banned</span>
                      ) : (
                        <span className="badge bg-secondary text-white">{member.status}</span>
                      )}
                    </td>
                    <td>{member.rank}</td>
                    <td>£{formatNumber(member.money)}</td>
                    <td>{member.weapon ? member.weapon : 'None'}</td>
                    <td>{member.protection ? member.protection : 'None'}</td>
                    <td>{formatNumber(member.workersRecruited)}</td>
                    <td>{formatNumber(member.soldiersRecruited)}</td>
                    <td>{formatNumber(member.guardsRecruited)}</td>
                    <td>{new Date(member.lastActive).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildingStats;
