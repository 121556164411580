import React, { useState } from 'react';
import {
  useKillSearchesListQuery,
  useKillTryMutation,
  useKillSearchMutation,
  useKillAttemptsQuery,
  useKillStatementsQuery,
  useHitlistDisplayQuery,
  useHitlistAddMutation,

} from '../../api/game';
import Response from '../../shared-components/response';

function KillTab({ setResponse }) {
  const { data: searches } = useKillSearchesListQuery();
  const [startSearch] = useKillSearchMutation();
  const [killTry] = useKillTryMutation();
  const [targetUsername, setTargetUsername] = useState('');
  
  const [killForm, setKillForm] = useState({
    targetUuid: '',
    bullets: '',
    reason: ''
  });

  const handleStartSearch = async () => {
    try {
      const result = await startSearch({ targetUsername }).unwrap();
      setResponse({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setTargetUsername('');
      }
    } catch (err) {
      setResponse({ message: 'Failed to start search', type: 'danger' });
    }
  };

  const handleKillSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await killTry(killForm).unwrap();
      setResponse({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setKillForm({
          targetUuid: '',
          bullets: '',
          reason: ''
        });
      }
    } catch (err) {
      setResponse({ message: 'Kill attempt failed', type: 'danger' });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setKillForm({
      ...killForm,
      [name.replace('kill_', '')]: value
    });
  };

  return (
    <div className="row">
      <div className="col-lg-5">
        <div className="card">
          <div className="card-header">Kill</div>
          <div className="card-body">
            <form onSubmit={handleKillSubmit}>
              <div className="form-group">
                <label>Username</label>
                <select 
                  className="form-control" 
                  name="kill_targetUuid"
                  value={killForm.targetUuid}
                  onChange={handleInputChange}
                >
                  <option value="">Select target</option>
                  {Object.values(searches || {})
                    .filter(search => search.status === 'found')
                    .filter(
                      (search, index, self) =>
                        index === self.findIndex(s => s.target.username === search.target.username)
                    )
                    .map(search => (
                      <option key={search.uuid} value={search.targetUuid}>
                        {search.target.username}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">  
                <label>Bullets</label>
                <input
                  type="text"
                  className="form-control"
                  name="kill_bullets"
                  value={killForm.bullets}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Reason</label>
                <input
                  type="text"
                  className="form-control"
                  name="kill_reason"
                  value={killForm.reason}
                  onChange={handleInputChange}
                />
              </div>
              <button type="submit" className="btn btn-danger">
                KILL
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="col-lg-7">
        <div className="card">
          <div className="card-header">Hire Detective (£250,000)</div>
          <div className="card-body">
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                value={targetUsername}
                onChange={e => setTargetUsername(e.target.value)}
              />
            </div>
            <button onClick={handleStartSearch} className="btn btn-primary">
              HIRE
            </button>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">Active Searches</div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Target</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {Object.values(searches || {})
  .filter(search => ['found', 'searching'].includes(search.status))
  .map(search => (
    <tr key={search.uuid}>
      <td>{search.target.username}</td>
      <td>
        {search.status === 'found' ? search.location.city : 'Searching...'}
      </td>
      <td>{search.status}</td>
      <td>
        <button className="btn btn-sm btn-danger">X</button>
      </td>
    </tr>
  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function Attempts() {
  const { data: attempts = [] } = useKillAttemptsQuery();

  // Filter out any entries that don't have a valid UUID
  const validAttempts = Object.values(attempts || {}).filter(attempt => attempt?.uuid);

  return (
    <div className="card">
      <div className="card-header">Kill Attempts</div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Attacker</th>
              <th>Target</th>
              <th>Location</th>
              <th>Bullets Used</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {validAttempts.map(attempt => (
              <tr key={attempt.uuid}>
                <td>{attempt?.attacker?.username || 'Unknown'}</td>
                <td>{attempt?.target?.username || 'Unknown'}</td>
                <td>{attempt?.location?.city || 'Unknown'}</td>
                <td>{attempt?.bulletsShot || 0}</td>
                <td>{attempt?.createdAt ? new Date(attempt.createdAt).toLocaleString() : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


function Statements() {
  const { data: statements = [] } = useKillStatementsQuery();
  
  // Filter out any entries that don't have a valid UUID
  const validStatements = Object.values(statements || {}).filter(statement => statement?.uuid);

  return (
    <div className="card">
      <div className="card-header">Witnessed Attempts</div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Attacker</th>
              <th>Target</th>
              <th>Revealed Name</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {validStatements.map(statement => (
              <tr key={statement.uuid}>
                <td>{statement?.attacker?.username || 'Unknown'}</td>
                <td>{statement?.defender?.username || 'Unknown'}</td>
                <td>{statement?.revealName || 'N/A'}</td>
                <td>{statement?.createdAt ? new Date(statement.createdAt).toLocaleString() : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


function Kill() {
  const [activeTab, setActiveTab] = useState('kill');
  const [response, setResponse] = useState({ message: '', type: '' });

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <Response message={response.message} type={response.type} />
      <div className="row">
        <div className="col-xl-12">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                href="#kill-tab"
                onClick={() => handleTabClick('kill')}
                className={`nav-link ${activeTab === 'kill' ? 'active' : ''}`}
              >
                Kill
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#attempts-tab"
                onClick={() => handleTabClick('attempts')}
                className={`nav-link ${activeTab === 'attempts' ? 'active' : ''}`}
              >
                Attempts
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#statements-tab"
                onClick={() => handleTabClick('statements')}
                className={`nav-link ${activeTab === 'statements' ? 'active' : ''}`}
              >
                Statements
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#hitlist-tab"
                onClick={() => handleTabClick('hitlist')}
                className={`nav-link ${activeTab === 'hitlist' ? 'active' : ''}`}
              >
                Hitlist
              </a>
            </li>
          </ul>
          <div className="tab-content panel rounded-0 p-3 m-0">
            <div
              className={`tab-pane fade ${activeTab === 'kill' ? 'active show' : ''}`}
              id="kill-tab"
            >
              {activeTab === 'kill' && <KillTab setResponse={setResponse} />}
            </div>

            <div
              className={`tab-pane fade ${activeTab === 'attempts' ? 'active show' : ''}`}
              id="attempts-tab"
            >
              {activeTab === 'attempts' && <Attempts />}
            </div>
            <div
              className={`tab-pane fade ${activeTab === 'statements' ? 'active show' : ''}`}
              id="statements-tab"
            >
              {activeTab === 'statements' && <Statements />}
            </div>
            <div
              className={`tab-pane fade ${activeTab === 'hitlist' ? 'active show' : ''}`}
              id="hitlist-tab"
            >
              {activeTab === 'hitlist' && <Hitlist />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Hitlist() {
  const { data: hitlist = [] } = useHitlistDisplayQuery();
  const [addToHitlist] = useHitlistAddMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  // Filter out any entries that don't have a valid UUID
  const validHitlist = Object.values(hitlist || {}).filter(item => item?.uuid);

  const [formData, setFormData] = useState({
    username: '',
    money: 0,
    bullets: 0,
    points: 0,
    reason: '',
    anonymous: false,
  });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const result = await addToHitlist(formData).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setFormData({
          username: '',
          money: 0,
          bullets: 0,
          points: 0,
          reason: '',
          anonymous: false,
        });
      }
    } catch (err) {
      setAlertData({ message: 'Failed to add to hitlist', type: 'error' });
    }
  };

  return (
    <>
      <Response message={alertData.message} type={alertData.type} />

      <div className="card">
        <div className="card-header">Current Hitlist</div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Rewards</th>
                <th>Listed By</th>
              </tr>
            </thead>
            <tbody>
              {validHitlist.map(item => (
                <tr key={item.uuid}>
                  <td>{item?.target?.username || 'Unknown'}</td>
                  <td>
                    {item?.money > 0 && `£${item.money.toLocaleString()} `}
                    {item?.bullets > 0 && `${item.bullets.toLocaleString()} bullets `}
                    {item?.points > 0 && `${item.points.toLocaleString()} points`}
                  </td>
                  <td>
                    {item?.anonymous === 'yes' ? 'Anonymous' : (item?.lister?.username || 'Unknown')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card mt-3">
        <div className="card-header">Add To Hitlist</div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                value={formData.username}
                onChange={e =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label>Cash Amount</label>
              <input
                type="number"
                className="form-control"
                value={formData.money}
                onChange={e =>
                  setFormData({ ...formData, money: parseInt(e.target.value) })
                }
              />
            </div>
            <div className="form-group">
              <label>Bullets Amount</label>
              <input
                type="number"
                className="form-control"
                value={formData.bullets}
                onChange={e =>
                  setFormData({
                    ...formData,
                    bullets: parseInt(e.target.value),
                  })
                }
              />
            </div>
            <div className="form-group">
              <label>Points Amount</label>
              <input
                type="number"
                className="form-control"
                value={formData.points}
                onChange={e =>
                  setFormData({ ...formData, points: parseInt(e.target.value) })
                }
              />
            </div>
            <div className="form-group">
              <label>Anonymous</label>
              <div>
                <input
                  type="checkbox"
                  checked={formData.anonymous}
                  onChange={e =>
                    setFormData({ ...formData, anonymous: e.target.checked })
                  }
                />{' '}
                Yes
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Add to Hitlist
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default Kill;
