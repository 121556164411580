import React, { useState } from 'react';
import { useFamilyListBusinessesQuery, useFamilyBuyBusinessMutation, useFamilySellBusinessMutation } from '../../../api/game';
import Response from '../../../shared-components/response';
import { formatNumber } from '../../../shared-components/numberFormat';

const BusinessTab = ({ familyInfo }) => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const { data: businesses, isLoading } = useFamilyListBusinessesQuery();
  const [buyBusiness] = useFamilyBuyBusinessMutation();
  const [response, setResponse] = useState({ message: '', type: '' });
  const businessesArray = businesses ? Object.values(businesses).filter(item => item.uuid) : [];

  const handleBuyBusiness = async () => {
    if (!selectedBusiness) return;
    try {
      const result = await buyBusiness({
        businessUuid: selectedBusiness,
      }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({ message: 'Failed to buy business', type: 'error' });
    }
  };

  const [sellBusiness] = useFamilySellBusinessMutation();

  const handleSellBusiness = async () => {
    if (!selectedBusiness) return;
    try {
      const result = await sellBusiness({
        businessUuid: selectedBusiness,
      }).unwrap();
      setResponse({ message: result.message, type: result.type });
    } catch (error) {
      setResponse({ message: 'Failed to sell business', type: 'error' });
    }
  };

  const [showSellModal, setShowSellModal] = useState(false);
  const [selectedBusinessDetails, setSelectedBusinessDetails] = useState(null);

  const handleSellClick = () => {
    const business = businessesArray.find(b => b.uuid === selectedBusiness);
    setSelectedBusinessDetails(business);
    setShowSellModal(true);
  };
  
  if (isLoading) return <div>Loading businesses...</div>;

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Family Businesses</h3>
        <Response message={response.message} type={response.type} />
        <table className="table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Business Name</th>
              <th>Price</th>
              <th>Owned</th>
            </tr>
          </thead>
          <tbody>
          {businessesArray.map(business => (
              <tr key={business.uuid}>
                <td>
                  <input
                    type="radio"
                    name="businessSelect"
                    value={business.uuid}
                    checked={selectedBusiness === business.uuid}
                    onChange={() => setSelectedBusiness(business.uuid)}
                  />
                </td>
                <td>{business.title}</td>
                <td>£{formatNumber(business.price)}</td>
                <td>{business.owned}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          className="btn btn-primary"
          onClick={handleBuyBusiness}
          disabled={!selectedBusiness}
        >
          Buy Selected Business
        </button>
        {(familyInfo.userRole === 'leader' ||
          familyInfo.userRole === 'rhm' ||
          familyInfo.userRole === 'lhm') && (
          <button
            className="btn btn-danger ml-2"
            onClick={handleSellClick}
            disabled={!selectedBusiness}
          >
            Sell Selected Business
          </button>
        )}
        {showSellModal && selectedBusinessDetails && (
          <div
            className="modal"
            style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Sale</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSellModal(false)}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to sell{' '}
                    {selectedBusinessDetails.title}?
                  </p>
                  <p>
                    You will receive £
                    {formatNumber(selectedBusinessDetails.price / 2)} back.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowSellModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleSellBusiness}
                  >
                    Sell Business
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessTab;
